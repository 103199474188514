<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <div class="content-banner-row">
    <div fxLayout="column" fxLayoutAlign="center start" [ngClass]="{
      'container-content-xlarge': isXLarge(),
      'container-content-large': isLarge(),
      'container-content-medium': isMedium(),
      'container-content-small': isSmall(),
      'container-content-xsmall': isXSmall()
    }">
      <div [ngClass]="{
        'content-xlarge': isXLarge(),
        'content-large': isLarge(),
        'content-medium': isMedium(),
        'content-small': isSmall(),
        'content-xsmall': isXSmall()
      }">
        <h1><span class="primary-title">Let's start saving</span></h1>
        <h2>
          Planet VG is the <span class="primary-title">climate-</span> and<span class="primary-title"> animal-friendliest way</span> to claim vouchers, collect stamps and be rewarded for your loyalty.
        </h2>
        <div [ngClass]="{
          'container-row-get-apps-xlarge': isXLarge(),
          'container-row-get-apps-large': isLarge(),
          'container-row-get-apps-medium': isMedium(),
          'container-row-get-apps-small': isSmall(),
          'container-row-get-apps-xsmall': isXSmall()
        }">
        <span><a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty" target="_blank"
            rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
              alt="Planet VG App - Google Play Store" /></a></span>
        <span><a href="https://apps.apple.com/app/planet-vg/id6505109649" target="_blank" rel="noopener"><img
              class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
              alt="Planet VG App - Apple App Store" /></a></span>

      </div>
      </div>
    </div>
    <div [ngClass]="{
      'container-product-banner-image-xlarge': isXLarge(),
      'container-product-banner-image-large': isLarge(),
      'container-product-banner-image-medium': isMedium(),
      'container-product-banner-image-small': isSmall(),
      'container-product-banner-image-xsmall': isXSmall()
    }">
      <img src="/assets/img/banners/xlarge/product-banner-storefront.webp" alt="planet-vg-vegan-loyalty-platform">
    </div>
  </div>
</div>